.desktop-menu {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    .desktop-menu-logo {
        padding-bottom: 10px;
    }

    .desktop-menu-nav {
        display: flex;

        .desktop-menu-nav-item {
            margin: 0 10px;
            position: relative;

            a:before {
                content: "";
                position: absolute;
                background-color: black;
                bottom: -3px;
                left: 0;
                width: 100%;
                height: 2px;
                visibility: hidden;
                transform: scaleX(0);
                transition: all 0.3s ease-in-out;
            }

            a:hover:before {
                visibility: visible;
                transform: scaleX(1);
            }
        }
    }
}