.mobile-menu {
    background-color: white;
    display: flex;
    justify-content: center;
	align-items: center;
    position: relative;
    height: 75px;

    .mobile-menu-header {
        display: flex;
        margin: 0 15px;
        width: 100%;

        .mobile-logo {
            flex: 1;
            img {
                width: 50px;
                height: auto;
            }
        }

        .mobile-menu-trigger {
            background: none;
            outline: none;
            border: none;
        }
        .mobile-menu-trigger:hover {
            cursor: pointer;
        }
    }
}

.mobile-menu-nav-modal {
    width: 100%;
    height: 100vh;

    .mobile-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: white;
        width: 100%;
        height: 100vh;
        
        .mobile-nav-item {
            text-align: center;
            padding: 10px 0;
            width: 100%;
        }
        .mobile-nav-item:hover {
            background-color: #f5f3ef;
        }

        .mobile-nav-close-btn {
            background: none;
            outline: none;
            border: none;
            margin-top: 30px;
        }

        .mobile-nav-close-btn:hover {
            cursor: pointer;
        }

        .mobile-nav-close-btn:active {
            border-radius: 5px;
        }
    }
}
