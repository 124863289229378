.contact {
    background-color: #f5f3ef;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;

    .contact-intro {
        max-width: 800px;
        margin-bottom: 30px;
        text-align: center;

        #heading {
            font-size: max(2.5vw, 30px);
        }

        #subheading {
            font-style: italic;
            font-size: max(2vw, 20px);
            margin-top: 20px;
        }

        p {
            color: gray;
        }
    }

    .contact-form {
        background-color: white;
        border: 2px solid black;
        border-radius: 10px;
        padding: 20px;

        .contact-form-item {
            display: flex;
            flex-wrap: wrap;
            justify-content: left;
            margin-bottom: 20px;

            h3 {
                text-align: left;
            }

            input, textarea {
                background: none;
                border: none;
                border-bottom: 2px solid black;
            }
        }

        .submit-btn {
            background-color: black;
            color: white;
            border-radius: 10px;
            outline: none;
            padding: 10px 15px;
        }
        .submit-btn:hover {
            background-color: white;
            color: black;
            cursor: pointer;
        }
    }
}

// larger screen
@media all and (min-width: 1071px) {
    .contact-form-item {
        h3 {
            width: 150px;
        }

        input, textarea {
            width: 300px;
        }
    }
}

// smaller screen
@media all and (max-width: 1070px) {
    .contact-form-item {
        h3 {
            width: 100%;
            margin-bottom: 7px;
        }

        input, textarea {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}
